const Constants = {
  mobileViewMaxWidth: 1000,
  sideBarWidth: 300,
  headerHeight: 120,
  copyrightHeight: 60,
  thumbnailFullContainerHeight: 150,
  thumbnailSmallContainerHeight: 50,
  cancelContainerHeight: 0,
  numColumns: 6,
  columnSpacing: 20,
  columnSpacingMobile: 10,
  peach: '#f8a178',
  showThumbnailWidthThreshold: 600,
  showThumbnailHeightThreshold: 500,

  linkedInLink: 'https://www.linkedin.com/in/sandra-zheng-8909aa187/',
  instagramLink: 'https://www.instagram.com/szheng__/',
  behanceLink: 'https://www.behance.net/sandrazheng',
  email: 'sandrazhengart@gmail.com',
};

export default Constants;
